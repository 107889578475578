import React, { Component } from 'react';
import axios from 'axios';
import PressItem from './pressitem';
import settings from '../../../settings';
import {FilterFacebookPosts} from "../../helpers/filterfacebookposts";
import {SortArray} from "../../helpers/sortarray";
import FacebookEmbeddedPost from '../../components/facebookembedddpost';
import { showErrorToast } from "../../helpers/toast";

class PressList extends Component {
  state = {
    visible: 4,
    channelId: settings.YOUTUBE_CHANNEL_ID,
    playlistId: 'PLXxnreMhRAgVNr0Ep0HMkUjeoH1dUL6L2',
    items: []
  };

  componentDidMount() {
    const getVideosData = () => {
      axios
        .get('https://www.googleapis.com/youtube/v3/playlists', {
          params: {
            part: ['title','contentDetails'],
            channelId: this.state.channelId,
            maxResults: 7,
            key: settings.GOOGLE_API_KEY
          }
        })
        .then((response) => {
          getVideos(this.state.playlistId);
        })
        .catch((err) => {
          showErrorToast(err)
        });
    };
    const getVideos = (playlistId) => {
      axios
        .get('https://www.googleapis.com/youtube/v3/playlistItems', {
          params: {
            part: 'snippet',
            playlistId: playlistId,
            maxResults: 20,
            key: 'AIzaSyAR8Ai9tGw03j_5ZbvZnsINIzbMw5284yg'
          }
        })
        .then((response) => {
          this.setState({
            items: this.state.items.concat(response.data.items)
          });
        })
        .catch((err) => {
          showErrorToast(err)
        });
    };
    const getFacebookPosts = () => {
      axios.get (
        'https://graph.facebook.com/v18.0/848438545166241/feed?fields=created_time,message,id,permalink_url,full_picture&access_token=EAALqSuZA6qosBO6V71fm55u4odl1uNu3khubdu6HDqCgBVDX1bnmOiHwwLFZAUXaJZCw9HMj5Ucegr9syydTZAShrHUA5GodXTGAq6T3sK1RWpltp7pPYV57qy9nPXOZCC3GZCYnOluVPG76QpcIcqZAFDRZA89SNR5TAV5lgptnXAlCgCzEVTxdmgO6'
      )
      .then((response) => {
        let filteredPosts = FilterFacebookPosts(response.data.data, "#BMWPDSPress");
        this.setState({
          items: this.state.items.concat(filteredPosts)
        });
      })
      .catch(function(error) {
        showErrorToast(error)
      });
    }
    getFacebookPosts();
    getVideosData();
  }

  loadMore = () => {
    this.setState({
      visible: this.state.visible + 2
    });
  };

  render() {
    const { items } = this.state;
    return (
      <div className="press__list">
         <div className="row">
            {!items.length && <h1>There are no videos yet</h1>}
            {items.length &&
              SortArray(items).slice(0, this.state.visible).map((item) => {
                if(item.permalink_url){
                  return (
                    <FacebookEmbeddedPost
                      key={item.id}
                      message={item.message}
                      full_picture={item.full_picture}
                      permalink_url={item.permalink_url}
                    />
                  );
                } else {
                  return (
                    <PressItem
                      key={item.id}
                      videoId={item.snippet.resourceId.videoId}
                      title={item.snippet.title}
                      description={item.snippet.description}
                    />
                  );
                }
              })}
          </div>
          {this.state.visible < items.length && (
            <button className="btn btn-blue load-more" onClick={this.loadMore} style={{ margin: `0 auto` }}>
              Load More
            </button>
          )}
      </div>
    );
  }
}

export default PressList;
