import React from "react";
import Popup from "reactjs-popup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import settings from "../../settings";
const closeIcon = `${settings.IMAGES_BASE_URL}/images/assets/close_btn_black.svg`;
const SimpleModal = ({ setOpen, open, children }) => {
	return (
		<div className="simple_modal">
			<Popup open={open} modal closeOnDocumentClick>
				{(close) => (
					<>
						<div>
							<button
								button
								className="close"
								onClick={close}
								onKeyDown={close}
							>
								<LazyLoadImage
									effect="blur"
									src={closeIcon}
									alt="close"
									className="close-icon"
								/>
							</button>
						</div>
						<div className="content">{children}</div>
					</>
				)}
			</Popup>
		</div>
	);
};
export default SimpleModal;
