export const SortArray = (array) => {
  let new_array = array.map(item => {
    if(item.permalink_url){
      item.created_at = new Date(item.created_time)
    } else {
      item.created_at = new Date(item.snippet.publishedAt)
    }
    return item;
  });
  let sorted_array = new_array.sort((a, b) => b.created_at - a.created_at);
  return sorted_array;
}