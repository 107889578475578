import React, { useState, useRef } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import cogoToast from "cogo-toast";
import AXIOS from "../../../axios-config";
import Spinner from "../../components/spinner";
import SimpleModal from "../simpleModal";
import ReCAPTCHA from "react-google-recaptcha";
import settings from "../../../settings";

const PressLearningForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [showSuccesMessage, setShowSuccesMessage] = useState(false);
	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");

	const initialValues = {
		firstname: "",
		lastname: "",
		email: "",
		mediaOutlet: "",
		phone: "",
		selectedInterest: [],
	};

	const validationSchema = Yup.object().shape({
		firstname: Yup.string()
			.required("Please enter your first name")
			.min(1, "Must have a character")
			.max(255, "Must be shorter than 255"),
		lastname: Yup.string()
			.required("Please enter your last name")
			.min(1, "Must have a character")
			.max(255, "Must be shorter than 255"),
		email: Yup.string()
			.email()
			.required("Please enter your email address")
			.matches(
				/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
				"Your email is incorrect"
			),
      phone: Yup.string()
			.required("Please enter your phone number")
			.matches(phoneRegExp, "Phone number is not valid"),
		mediaOutlet: Yup.string()
			.required("Please enter your media outlet")
			.min(1, "Must have a character")
			.max(255, "Must be shorter than 255"),
		selectedInterest: Yup.array().required(
			"Please select at least one of the interests"
		),
	});

  
  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }

	const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const headers = {
      'captchaToken': captchaToken
    };

    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return
    }
		setIsLoading(true);
		setSubmitting(true);
		setShowSuccesMessage(false);

		AXIOS.post("/api/press-inquiry", values, {headers})
			.then(() => {
        errorHandling(setSubmitting);
				resetForm();
				setShowSuccesMessage(true);
			})
			.catch((err) => {
				errorHandling(setSubmitting);
				cogoToast.error("Your form wasn't submitted", {
					position: "top-center",
				});
			});
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<div className="input">
							<div className="input__group">
								<label htmlFor="name">
									First Name<sup>*</sup>
								</label>
								<Field
                  aria-label="firstname"
									type="text"
									name="firstname"
									className={
										touched.firstname && errors.firstname ? "has-error" : null
									}
								/>
								{errors.firstname && touched.firstname ? (
									<span className="input-feedback">{errors.firstname}</span>
								) : null}
							</div>
							<div className="input__group">
								<label htmlFor="name">
									Last Name<sup>*</sup>
								</label>
								<Field
                  aria-label="lastname"
									type="text"
									name="lastname"
									className={
										touched.lastname && errors.lastname ? "has-error" : null
									}
								/>
								{errors.lastname && touched.lastname ? (
									<span className="input-feedback">{errors.lastname}</span>
								) : null}
							</div>
							<div className="input__group">
								<label htmlFor="email">
									Email Address<sup>*</sup>
								</label>
								<Field
                  aria-label="email"
									type="email"
									name="email"
									className={touched.email && errors.email ? "has-error" : null}
								/>
								{errors.email && touched.email ? (
									<span className="input-feedback">{errors.email}</span>
								) : null}
							</div>
              <div className="input__group">
								<label htmlFor="phone">
									Phone Number<sup>*</sup>
								</label>
								<Field
                  aria-label="phone"
									type="tel"
									name="phone"
									className={touched.phone && errors.phone ? "has-error" : null}
								/>
								{errors.phone && touched.phone ? (
									<span className="input-feedback">{errors.phone}</span>
								) : null}
							</div>
							<div className="input__group">
								<label htmlFor="mediaOutlet">
									Media Outlet<sup>*</sup>
								</label>
								<Field
                  aria-label="mediaOutlet"
									type="text"
									name="mediaOutlet"
									className={
										touched.mediaOutlet && errors.mediaOutlet
											? "has-error"
											: null
									}
								/>
								{errors.mediaOutlet && touched.mediaOutlet ? (
									<span className="input-feedback">{errors.mediaOutlet}</span>
								) : null}
							</div>
              <div className="input__group">
                  <label htmlFor="notes">Additional requests (our team will reach out to you directly)</label>
                  <Field as="textarea" name="notes" id="notes" />
              </div>

							<div className="checkbox">
								<h5>I'M INTERESTED IN (check all that apply)</h5>
								<div className="checkbox__group">
									<Field
                    aria-label="PerformanceCenterEastAndWest"
										type="checkbox"
										name="selectedInterest"
										id="PerformanceCenterEastAndWest "
										value="Performance Center East and West"
									/>
									<label htmlFor="PerformanceCenterEastAndWest">
										Performance Center East and West
									</label>
								</div>
								<div className="checkbox__group">
									<Field
                    aria-label="bmw"
										type="checkbox"
										name="selectedInterest"
										id="checkbox__bmw"
										value="BMW"
									/>
									<label htmlFor="BMW">BMW</label>
								</div>
								<div className="checkbox__group">
									<Field
                    aria-label="mini"
										type="checkbox"
										name="selectedInterest"
										id="checkbox__mini"
										value="MINI"
									/>
									<label htmlFor="MINI">MINI</label>
								</div>
								<div className="checkbox__group">
									<Field
										type="checkbox"
										name="selectedInterest"
										id="UsRiderAcademy"
										value="US Rider Academy"
									/>
									<label htmlFor="UsRiderAcademy">US Rider Academy</label>
								</div>
								<div className="checkbox__group">
									<Field
										type="checkbox"
										name="selectedInterest"
										id="BMWDelivery"
										value="BMW Delivery"
									/>
									<label htmlFor="BMWDelivery">BMW Delivery</label>
								</div>
								<div className="checkbox__group">
									<Field
										type="checkbox"
										name="selectedInterest"
										id="RaceLicensingProgram"
										value="Race Licensing Program"
									/>
									<label htmlFor="RaceLicensingProgram">
										Race Licensing Program
									</label>
								</div>
								{errors.selectedInterest && touched.selectedInterest ? (
									<span className="input-feedback">
										{errors.selectedInterest}
									</span>
								) : null}
							</div>
              <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/><br/>
							<button
								className="btn btn-blue"
								type="submit"
								disabled={isSubmitting}
							>
								{isLoading ? <Spinner /> : "Submit"}
							</button>
						</div>
					</form>
				)}
			</Formik>
			<SimpleModal open={showSuccesMessage} setOpen={setShowSuccesMessage}>
				<p>
        Thank you for your interest. You can download our media kit here: 
        <br /> <br />
         <a href="https://www.dropbox.com/sh/elako8jtw1o5x9a/AAAQlUJUDw91wpdXyW8vmQY_a?dl=0" target="_blank" rel="noopener noreferrer">
           <button className="btn btn-bordered">Access your kit now</button>
         </a>

				</p>
			</SimpleModal>
		</>
	);
};
export default PressLearningForm;
