import React from 'react';

const PressItem = (props) => {
  return (
    <div className="col-6">
      <div className="press__item">
        <div className="press__item__content">
          <div className="press__youtube">
            <iframe className="responsive-iframe" title="responsive-iframe" src={`https://youtube.com/embed/${props.videoId}`} frameBorder="0" aria-label="responsive-iframe" />
          </div>
          <h6>{props.title}</h6>
          <p>{props.description}</p>
          <a href={`https://youtube.com/embed/${props.videoId}`} target="_blank" rel="noopener noreferrer">
            <button className="btn btn-bordered">Watch on Youtube</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PressItem;
